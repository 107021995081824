import { Table, PageHeader, Tag, Button, Input, Space, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import Axios from "axios";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import { openNotification } from "../functions/notification";
import { CSVLink, CSVDownload } from "react-csv";

class User extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
    searchInput: React.createRef(null),
    data: []
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { token } = this.props;
    await Axios.get(
      "https://xearth-service.com/vquiz/user/get-all?key=" + token
    )
      .then((res) => {
        console.log(res.data.users);
        this.setState({ data: res.data.users });
      })
      .catch((err) => {
        return openNotification("error", err?.response?.data?.message);
      });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({
      searchText: ""
    });
  };

  render() {
    const { searchInput, searchText, searchedColumn, data } = this.state;

    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              this.handleSearch(selectedKeys, confirm, dataIndex)
            }
            style={{
              marginBottom: 8,
              display: "block"
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                this.handleSearch(selectedKeys, confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && this.handleReset(clearFilters)}
              size="small"
              style={{
                width: 90
              }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({
                  closeDropdown: false
                });
                this.setState({
                  searchText: selectedKeys[0],
                  searchedColumn: dataIndex
                });
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? "#1890ff" : undefined
          }}
        />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        )
    });

    const columns = [
      {
        title: "Id",
        dataIndex: "_id",
        ...getColumnSearchProps("_id")
      },
      {
        title: "Nom",
        dataIndex: "firstname",
        ...getColumnSearchProps("firstname")
      },
      {
        title: "Prénom",
        dataIndex: "lastname",
        ...getColumnSearchProps("lastname")
      },
      {
        title: "Numéro de téléphone",
        dataIndex: "number",
        ...getColumnSearchProps("number"),
        render: (text) => (
          <Tag color="red">
            <b>{text}</b>
          </Tag>
        )
      },
      {
        title: "Réponses",
        dataIndex: "questions",
        render: (text, record) => (
          <Tag color={"blue"}>
            {text.map((item) => (
              <div>
                {"Question: " + item?.Q}
                <br />
                {"Reponse: " + item?.A1}
              </div>
            ))}
          </Tag>
        )
      }
    ];

    return (
      <div style={{}}>
        <PageHeader
          className="site-page-header"
          // onBack={() => null}
          title="Gestion des reponses"
          subTitle="Liste des reponses"
          extra={[
            <CSVLink
              data={data.map((item, index, arr) => {
                return {
                  index: index + 1,
                  firstname: item.firstname,
                  lastname: item.lastname,
                  number: item.number,
                  q1: item.questions[0]?.Q,
                  a1: item.questions[0]?.A1,
                  q2: item.questions[1]?.Q,
                  a2: item.questions[1]?.A1
                };
              })}
            >
              Exporter CSV/Excel
            </CSVLink>
          ]}
        >
          <Row style={{ marginTop: 10 }}>
            <b
              style={{
                fontSize: 30
              }}
            >
              Total inscrit : {data.length}
            </b>
          </Row>
          <Table
            style={{
              marginTop: 20
            }}
            columns={columns}
            dataSource={data}
            size="middle"
          />
        </PageHeader>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state?.user?.token
  };
};

const mapDispatchStoreToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchStoreToProps)(User);
