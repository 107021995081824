import { UserOutlined } from "@ant-design/icons";
import user from "./User";

const listMenu = [
  {
    icon: <UserOutlined />,
    label: "user",
    path: "/user",
    component: user
  }
];

export { listMenu };
